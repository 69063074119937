h1 {
  font-size: 48px;
  line-height: 60px;
  font-family: 'Fjalla One';
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h2 {
  font-size: 40px;
  line-height: 56px;
  font-family: 'Fjalla One';
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h3 {
  font-family: 'Fjalla One';
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 40px;
}