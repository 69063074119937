.c-modal {

  .modal-overlay {
    background: rgba(33, 33, 33, 0.9) !important;
  }

  .modal-container {
    background: #000000;
    box-shadow: -2px 5px 28px 2px rgba(0,0,0,0.44);

    .modal-title {
      color: #868686;
      font-weight: 300;
      border-bottom: 1px solid #323232;
      padding-top: 30px;
      padding-bottom: 20px;
      margin-bottom: 0;

      .h5 {
        color: $primary-color;
        font-weight: 300;
        line-height: 40px;
      }
    }

    .modal-body {
      h2 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
        text-align: center;

        &:before {
          height: 0;
          bottom: 0;
        }
      }

      h3 {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
        text-align: center;
      }
    }

    .acceptance {
      color: #868686;
      font-weight: 300;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .small {
      color: #868686;
      font-weight: 300;
      font-size: 14px;
      line-height: 50px;
    }
  }
}