/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  padding-bottom: 1em;
  background: $dark-color;
  height: 100vh;
  position: relative;
  z-index: 1;

  &.c-header-hero-single {
    @media (max-width: $mobile-bp) {
      height: 100vh;

      &-small {
        height: 72vh;
      }
    }

    @media (min-width: $mobile-bp) {
      height: calc(100vw / 1.6);
    }

    @media (min-width: $size-xl) {
      height: calc(100vw / 2);
    }

    @media (min-width: $size-2x) {
      height: calc(100vw / 2.8);
    }
  }

  &.c-header-hero-empty {
    @media (max-width: $mobile-bp) {
      height:60px;
    }
    
    @media (min-width: $mobile-bp) {
      height: 190px;
    }
  }
}
  