/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: #0B0A0A;
  padding-top: 70px;
  padding-bottom: 100px;

  @media (max-width: $mobile-bp) {
    background: $dark-color;
    padding-top: 0;
    padding-bottom: 50px;
    color: #868686;
  }

  &-widgets {
    list-style-type: none;
    margin-bottom: 30px;

    h2.widgettitle {
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0;
    }

    li.widget {
      border-left: 1px solid #3d3c3c;
      padding-left: 20px;

      .textwidget {
        color: #868686;
        font-weight: 300;

        a {
          color: #868686;
          display: block;
          padding-left: 30px;
          background-position: center left;
          background-repeat: no-repeat;
          line-height: 30px;
          transition: $global-transition;

          &.ico-tel {
            background-image: url(../assets/images/ico-telefon-grau.svg);
            background-size: 19px;
          }

          &.ico-mail {
            background-image: url(../assets/images/ico-email-grau.svg);
            background-size: 19px;
          }

          &.ico-face {
            background-image: url(../assets/images/ico-facebook-grau.svg);
            background-size: 19px;
          }

          &:hover {
            text-decoration: none;
            color: $primary-color;
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin-left: 0;

      li {
        a {
          color: #868686;
          font-weight: 300;
          transition: $global-transition;

          &:hover {
            text-decoration: none;
            color: $primary-color;
          }

          &:focus {
            box-shadow: none;
            text-decoration: none;
          }
        }
      }
    }
  }
}
  