.c-single-project {
  padding-top: 50px;

  @media (max-width: $mobile-bp) {
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: $mobile-bp) {
    padding-bottom: 250px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    font-size: 48px;
    line-height: 60px;
    width: 100%;
    position: relative;
    margin-bottom: 60px;

    @media (max-width: $mobile-bp) {
      font-size: 38px;
      line-height: 50px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 50px;
      height: 5px;
      bottom: -30px;
      background-color: #fff;
    }
  }
  &--contentmeta {
    &--location {
      font-size: 17px;
      color: $primary-color;
      opacity: 0.5;
      margin-bottom: 30px;

      span {
        background-image: url(../assets/images/ico-location.svg);
        background-repeat: no-repeat;
        padding-left: 30px;
      }
    }
  }

  &--content {
    color: #868686;
    font-size: 17px;
    line-height: 27px;


    &--text {
      @media (max-width: $mobile-bp) {
        margin-bottom: 30px;
      }
      @media (min-width: $mobile-bp) {
        padding-right: 120px;
      }

      iframe,
      .wp-video,
      .c-embed--video {
        width: 100%;
      }
    }

    &--facts {
      h4 {
        color: $primary-color;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
      }
      ul {
        list-style-type: none;
        margin-left: 0;
        margin-bottom: 50px;
      }
    }
  }

  &--gallery {
    @media (max-width: $mobile-bp) {
      padding-top: 10px;
    }
  
    @media (min-width: $mobile-bp) {
      padding-top: 250px;
    }

    &--item {
      background-size: cover;
      background-position: center center;

      @media (max-width: $mobile-bp) {
        height: calc(100vw / 1.92);
        margin-bottom: 20px;
      }
    
      @media (min-width: $mobile-bp) {
        height: calc(50vw / 1.92);
      }
    }
  }
}