.e-forms {
  input[type=text],
  input[type=email],
  textarea {
    background: #151515;
    border:0;
    border-bottom: 3px solid #464646;
    color: $primary-color;
  }

  textarea {
    height: 70px;
    margin-top: 10px;
    resize: none;
    &:focus {
      border-bottom: 3px solid #464646;
    }
  }

  input[type=checkbox] {
    margin-right: 10px;
  }

  input[type=submit] {
    &:focus {
      box-shadow: none;
    }
  }

  // spectre overrides
  .form-group {
    margin-bottom: 20px;
  }

  .form-select {
    background: #151515 url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") no-repeat right 0.35rem center/0.4rem 0.5rem !important;
    border:0;
    border-bottom: 3px solid #464646;
  }
}