.c-teaser-news {
  margin-top: 130px;

  @media (max-width: $mobile-bp) {
    margin-top: 60px;
  }

  &--header {
    margin-bottom: 60px;
    margin-left:0;
    margin-right:0;

    &--title {
      font-size: 17px;
      line-height: 23px;
      text-transform: uppercase;
      font-weight: 500;

      @media (max-width: $mobile-bp) {
        font-family: 'Fjalla One';
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.1em;
        text-align: center;
      }
    }

    &--link {
      text-align: right;

      a {
        display: inline-block;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 46px;
        height: 46px;
        font-weight: 500;
        padding-right: 60px;
        background: url(../assets/images/ico-weiter.svg) no-repeat;
        background-position: center right;
        background-size: 46px 46px;

        &:hover {
          text-decoration: none;
          animation: pulsate 0.9s infinite;
        }

        &:focus {
          text-decoration: none;
          box-shadow: none;
        }
      }
    }
  }

  &--list {

    &--item {
      margin-bottom: 50px;
      width: 30%;
      min-height: 520px;
      background-color: #141414;
      padding-left: 0;
      padding-right: 0;
      transition: $global-transition;

      @media (max-width: $mobile-bp) {
        width: 90%;
      }

      .card-image {
        height: 200px;
        border-bottom: 5px solid #272727;
        background-size: cover;
        background-position: center center;
        transition: $global-transition;
      }

      .card-content {
        min-height: 280px;
        transition: $global-transition;

        .card-header {
          padding: 10%;
          font-size: 24px;
          line-height: 34px;
          font-weight: 500;
        }

        .card-body {
          padding: 10%;
          padding-top: 0;
          font-size: 17px;
          line-height: 27px;
          font-weight: 300;
          color: #868686;
        }
      }

      &:hover {
        text-decoration: none;
        opacity: 0.7;
        //animation: pulsate 0.9s infinite;

        .card-image,
        .card-content {
          border-color: #fff;
        }
      }

      &:focus {
        box-shadow: none;
        text-decoration: none;
      }

      @media (min-width: $mobile-bp) {
      &--double {
        display: flex;
        flex-flow: row-reverse;
        width: 63.3%;
        
        .card-image {
          min-width: 50%;
          height: 100%;
          border-bottom: 0;
          background-size: cover;
          background-position: center center;
        }
        .card-content {
          border-top: 5px solid #272727;

          .card-header {
            padding: 10%;
          }
          .card-body {
            padding: 10%;
            padding-top: 0;
          }
        }
      }
      }
    }
  }

  &--footer {
    text-align: center;
    
    &--link {
      display: none;

      @media (max-width: $mobile-bp) {
        display: inline-block;
      }
    }
  }
}

body.single-post {
  a.menu-item-3010 {
    color: $primary-color;
    
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}