.c-single-post {
  padding-top: 100px;
  padding-bottom: 250px;

  h1 {
    font-size: 48px;
    line-height: 60px;
    position: relative;
    margin-bottom: 80px;
    text-align: left;

    &:before {
      content: "";
      position: absolute;
      width: 50px;
      height: 5px;
      bottom: -30px;
      left: 0%;
      margin-left: 0;
      background-color: #fff;
    }
  }

  &--content {
    color: #868686;
    font-size: 17px;
    line-height: 27px;
  }
}