.c-rubric-technology {

    &--sidebar {
      background: #000;

      @media (min-width: $mobile-bp) {
        padding-left: 3em;
      }

      .c-rubric-technology--terms {
        list-style-type: none;

        @media (max-width: $mobile-bp) {
          margin: 10px;
        }

        li.nav-lvl1-item {
          margin-bottom: 60px;

          // List item cat 1st level
          @media (max-width: $mobile-bp) {
            margin-bottom:0;
            border-bottom: 1px solid rgba(255,255,255,0.2);
          }
          
          a {
            font-family: 'Fjalla One';
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-size: 28px;
            line-height: 40px;
            transition: $global-transition;

            @media (max-width: $mobile-bp) {
              display: inline-block;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 10px;
              padding-bottom: 10px;
            }

            &:hover {
              text-decoration: none;
              color: #868686;
            }

            &:focus {
              text-decoration: none;
              box-shadow: none;
            }
          }

          .nav-lvl1-open-lvl2 {
            display: inline-block;
            width: 38px;
            height: 38px;
            background-image: url(../assets/images/ico-dropdown.svg);
            background-repeat: no-repeat;
            vertical-align: sub;

            @media (min-width: $mobile-bp) {
              display: none;
            }
          }

          &.current-menu-item,
          &.current-menu-parent {

            a {
              text-decoration: underline;
            }

            ul.nav-lvl2 li a {
              text-decoration: none;
            }
          }
          
          ul.nav-lvl2 {
            list-style-type: none;
            margin-left: 0;

            @media (max-width: $mobile-bp) {
              margin-top:0;
              margin-bottom: 20px;
            }

            li.nav-lvl2-item {
              color: #868686;
              margin-bottom: 0;
              margin-top: 0;

              a {
                font-family: 'Roboto';
                font-weight: 300;
                letter-spacing: 0;
                text-transform: none;
                font-size: 17px;
                line-height: 30px;
                color: #868686;

                @media (max-width: $mobile-bp) {
                  padding-top:0;
                  padding-bottom: 0;
                }

                &:hover {
                  text-decoration: none;
                  color: $primary-color;
                }

                &:focus {
                  text-decoration: none;
                  box-shadow: none;
                }
              }

              &.current-menu-item,
              &.current-menu-parent {
                a {
                  color: $primary-color;
                  text-decoration: none;
                }
              }
            }


            @media (max-width: $mobile-bp) {
              display:none;
            }
          }
        }
      }

      .widget {
        padding-left: 0.8rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        h2 {
          color: #fff;
        }

        p {
          color: #868686;
          font-weight: 200;
        }
      }
    }

    &--content {
      padding:0;
    
      @media (max-width: $mobile-bp) {
        margin-top: 50px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 50px;
      }

      @media (min-width: $mobile-bp) {
        padding-left: 30px;
        padding-right: 30px;
      }

      h2 {
        font-size: 28px;
        margin-bottom: 30px;

        @media (max-width: $mobile-bp) {
          font-size: 32px;
          word-wrap: break-word;
        }
      }

      .card {
        transition: $global-transition;
        
        @media (max-width: $mobile-bp) {
          margin-bottom: 60px;
        }
        
        @media (min-width: $mobile-bp) {
          margin-bottom: 100px;
        }

        .card-image {
          width: 80%;
          height: 0;
          padding-bottom: 80%;
          margin: auto;
          margin-bottom: 10px;
          background-color: #fff;
          position: relative;
          transition: $global-transition;

          @media (max-width: $mobile-bp) {
            margin-bottom: 20px;
          }

          &--element {
            position: absolute;
            width: 70%;
            height: 0;
            padding-bottom: 70%;
            left: 15%;
            top:15%;
            background-size: cover;
            background-position: center center;
          }
        }

        .card-body {
          font-family: 'Fjalla One';
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 20px;
          line-height: 26px; 
          width: 80%;
          margin: auto;
          min-height: 100px;

          @media (max-width: $mobile-bp) {
            font-size: 24px;
            line-height: 32px; 
          }

          @media (min-width: $mobile-bp) and (max-width: 1300px) {
            font-size: 13px;
          }

          &--content {
            position: absolute;
            width: 100%;
            bottom: 0;
            word-wrap: break-word;
          }

          &--linkicon {
            position: absolute;
            bottom:0;
            right:0;
            display: inline-block;
            min-height: 30px;
            width: 100%;
            background-image: url(../assets/images/ico-weiter-sm.svg);
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 100% 100%;
            vertical-align: bottom;
            transition: $global-transition;
          }
        }

        &:hover {
          text-decoration: none;

          .card-image {
            opacity: 0.7;
          }

          .card-body {
            &--linkicon {
              background-image: url(../assets/images/ico-weiter-sm-active.svg);
            }
          }
        }

        &:focus {
          box-shadow: none;
          text-decoration: none;
        }
      }

      .c-rubric-technology--richcontent {
        color: #868686;

        table {
          padding:0;
          margin:0;
          width:100% !important;
        }

        iframe,
        .wp-video,
        .c-embed--video {
          width: 100%;
        }
      }

      .c-rubric-technology--tables {
        .table {
          margin-bottom: 30px;
          color: #7E8686;

          .tcol {
            width: 50%;
          }
        }
      }

      .c-rubric-technology--overview-link {
        padding-top: 10px;
        padding-bottom: 40px;
        border-bottom: 1px solid #323232;
        margin-bottom: 70px;

        @media (max-width: $mobile-bp) {
          display: none;
        }

        a {
          font-family: 'Fjalla One';
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-size: 28px;
          background: url(../assets/images/ico-zurueck.svg) no-repeat;
          padding-left: 50px;

          &:hover {
            animation: pulsate 0.9s infinite;
            text-decoration: none;
          }

          &:focus {
            box-shadow: none;
            text-decoration: none;
          }
        }
      }

      .c-rubric-technology--image {
        .card-image {
          width: 80%;
          height: 0;
          padding-bottom: 80%;
          margin: auto;
          margin-bottom: 50px;
          background-color: #fff;
          position: relative;

          @media (max-width: $mobile-bp) {
            margin-bottom: 20px;
          }

          &--element {
            position: absolute;
            width: 70%;
            height: 0;
            padding-bottom: 70%;
            left: 15%;
            top:15%;
            background-size: cover;
            background-position: center center;
          }

          img {
            border-radius: 50%;
          }
        }

        &--mobile {
          margin-bottom: 40px;

          div {
            width: 100%;
            padding-bottom: 100%;
          }
        }
      }

      .c-rubric-technology--meta {
        margin-top: 70px;
        border-top: 1px solid #323232;
        padding-top: 50px;
        margin-bottom: 50px;

        &--price {
          .price {
            font-family: 'Fjalla One';
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-size: 28px;
            line-height: 40px;
          }

          .subline {
            break-before: left;
            color: #868686;
            font-size: 14px;
          }
        }

        &--request {
          float:right;
        }

        &--datasheet {
          margin-left: 10px;
          float:right;
          background: url(../assets/images/ico-pdf.svg) no-repeat;
          background-position: right 30px center;
          background-size: 20px;
          padding-right: 60px;
        }
      }

      &--single {
        h2 {
          font-size: 40px;
          line-height: 50px;
          position: relative;
          margin-bottom: 60px;

          &:before {
            content: "";
            position: absolute;
            width: 50px;
            height: 5px;
            bottom: -30px;
            left: 25px;
            margin-left: -25px;
            background-color: #fff;
          }
        }
      }

    }

    .stickies {
      margin-bottom: 100px;

      .card-body {
        font-size: 24px;
        line-height: 35px;
        text-align: center;
      }
    }

}

body.tax-technikkategorie,
body.single-technik {
  a.menu-item-3008 {
    color: $primary-color;
    
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}