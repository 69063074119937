.c-navbar {

  // Desktop
  @media (min-width: $mobile-bp) {
    &--logo {
      &:focus {
        box-shadow: none;
      }
    }
    &--desktop {
      padding: $unit-10;
      padding-bottom: 30px;
      position: absolute;
      z-index: 10;
      width: 100%;
      background: linear-gradient(to bottom, rgba(19,19,19,1) 0%, rgba(76,76,76,0) 100%);

      &--logo {
        img {
          width: 250px; 
        }

        &:hover img {
          animation: pulsate 0.9s infinite;
        }
      } 

      &:focus {
        box-shadow: 0;
        outline: none;
      }

      &--navitem {
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: $unit-6;
        color: $gray-color-dark;
        transition: all 0.3s ease-in-out;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 30%;
          height: 2px;
          bottom: 0;
          left: 35%;
          background-color: #fff;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out 0s;
        }

        &:hover {
          text-decoration: none;

          &:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }

        &:focus {
          box-shadow: none;
          text-decoration: none;
        }

        &.current-menu-item {
          color: $primary-color;
          text-decoration: none;

          &:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      }

      &--searchbox {
        input {
          color: transparent;
          background: $dark-color url(../assets/images/ico-search.svg) no-repeat;
          //background-attachment: fixed;
          background-position-y: center;
          background-position-x: calc(100% - 12px);
          background-size: 17px;
          height: 44px;
          width: 44px;
          line-height: 44px;
          padding-left: 15px;
          padding-right: 25px;
          border: 2px solid $light-color;
          border-radius: 22px;
          transition: all 0.3s ease-in-out;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }

          &:focus {
            opacity: 1;
            color: $light-color;
            outline: none;
            width: 150px;
          }
        }
      }

      &.sticky {
        position:fixed;
        top:0;
      }
    }
    
    &--mobile {
      display: none;
    }
  }

  // Mobile
  @media (max-width: $mobile-bp) {
    &--desktop {
      display: none;
    }
    
    &--mobile {
      display: block;
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 70px;
      background: linear-gradient(to bottom, rgba(19,19,19,1) 0%, rgba(76,76,76,0) 100%);

      &--logo {
        position: absolute;
        z-index: 2;
        top: 20px;
        left:20px;
        img {
          width: 48px;
        }
      }

      &--button {
        width: 48px;
        height: 48px;
        background-size: 48px 48px;
        border:0;
        position: absolute;
        z-index: 2;
        top:20px;
        right:20px;
        transition: $global-transition;

        &--open {
          background: url(../assets/images/ico-burger.svg) no-repeat;
        }

        &--close {
          background: url(../assets/images/ico-burger-close.svg) no-repeat;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      &--menu {
        height: 100vh;
        width: 100vw;
        position: absolute;
        z-index: 1;
        background: $dark-color;
        transition: $global-transition;
      }

      &--navitem {
        display: block;
        text-align: center;
        font-size: 32px;
        line-height: 40px;
        font-family: 'Fjalla One';
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-right: $unit-6;
        color: $light-color;
        transition: all 0.3s ease-in-out;
        position: relative;
        margin: 20px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
          outline: none;
          box-shadow: none;
        }

        &:active {
          text-decoration: none;
        }
      }

      &--searchbox {
        input {
          position: absolute;
          width: 60px;
          right: 75px;
          top:20px;
          color: transparent;
          background: rgba(0,0,0,0.3) url(../assets/images/ico-search.svg) no-repeat;
          //background-attachment: fixed;
          background-position-y: center;
          background-position-x: calc(100% - 12px);
          background-size: 17px;
          height: 48px;
          width: 48px;
          line-height: 48px;
          padding-left: 15px;
          padding-right: 25px;
          border: 2px solid $light-color;
          border-radius: 24px;
          transition: all 0.3s ease-in-out;
          opacity: 1;

          &:focus {
            opacity: 1;
            color: $light-color;
            outline: none;
            width: 210px;
          }
        }
      }

      &--navwrap {
        list-style-type: none;
      }
    }
  }
}
