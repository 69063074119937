@import "node_modules/swiper/dist/css/swiper";


.swiper-container-small {
  @media (max-width: $mobile-bp) {
    height: 72vh !important;
  }

  @media (min-width: $mobile-bp) {
    height: calc(100vw / 1.6) !important;
  }

  @media (min-width: $size-xl) {
    height: calc(100vw / 2) !important;
  }

  @media (min-width: $size-2x) {
    height: calc(100vw / 2.8) !important;
  }
}
