.c-search-results {
  padding-top: 20px;
  padding-bottom: 250px;

  @media (max-width: $mobile-bp) {
    padding-top: 40px;
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    font-size: 48px;
    line-height: 60px;
    position: relative;
    margin-bottom: 80px;
    text-align: left;

    @media (max-width: $mobile-bp) {
      word-wrap: break-word;
      font-size: 30px;
      line-height: 40px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 50px;
      height: 5px;
      bottom: -30px;
      left: 0%;
      margin-left: 0;
      background-color: #fff;
    }
  }

  &--content {
    color: #868686;
    font-size: 17px;
    line-height: 27px;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 25px;

    @media (max-width: $mobile-bp) {
      word-wrap: break-word;
      font-size: 25px;
      line-height: 32px;
    }
  }
}