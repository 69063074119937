.swiper-container {
  height: 100vh;
}

.c-hero {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 2;
  
  &--slide {
    position: relative;
    top: 0;
    left: 0;

    &.c-hero-single {
      @media (max-width: $mobile-bp) {
        height: 100vh;

        &-small {
          height: 72vh;
        }
      }

      @media (min-width: $mobile-bp) {
        height: calc(100vw / 1.6);
      }

      @media (min-width: $size-xl) {
        height: calc(100vw / 2);
      }

      @media (min-width: $size-2x) {
        height: calc(100vw / 2.8);
      }
    }
  }

  .c-hero-content {
    position: absolute;
    width: 100%;
    z-index: 3;
    opacity: 0;

    &--btn {
      margin-bottom: $unit-10;
      appearance: none;
      width: 70px;
      height: 70px;
      border: 0;
      transition: $global-transition;
      background-position: center center;

      &-play {
        background: url(../assets/images/ico-play.svg) no-repeat;
      }

      &-pause {
        background: url(../assets/images/ico-pause.svg) no-repeat;
      }

      &.hidden {
        display: none;
        opacity: 0;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      @media (min-width: $mobile-bp) {
        &:hover {
          animation: pulsate 1s infinite;
          cursor: pointer;
        }
      }
    }

    h1 {
      @media (max-width: $mobile-bp) {
        width: 80%;
        margin-left: 10%;
        word-wrap: break-word;
        font-size: 32px;
        line-height: 40px;
      }

      @media (min-width: $mobile-bp) {
        width: 80%;
        margin-left: 10%;
      }
    }

    &--subtitle {
      color: $gray-color-light;
      font-weight: 300;
      width: 80%;
      margin-left: 10%;

      @media (max-width: $mobile-bp) {
        font-size: 16px;
        line-height: 24px;
      }

      @media (min-width: $mobile-bp) {
        font-size: 22px;
        line-height: 31px;
      }
    }

    &--location {
      font-size: 17px;
      color: $primary-color;
      opacity: 0.5;

      span {
        background-image: url(../assets/images/ico-location.svg);
        background-repeat: no-repeat;
        padding-left: 30px;
      }
    }

    &--btn-ghost {
      margin-top: $unit-10; 
      //padding: $unit-3 $unit-4 $unit-3 $unit-4;
    }

    &.playing {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  .c-hero-image {
    position: absolute;
    z-index: 1;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    transition: $global-transition;

    &.hidden {
      display: none;
      opacity: 0;
    }
  }

  .c-hero-video {
    display: block;
    position: absolute;
    z-index: 0;
    min-height: 100%; 
    min-width: 100%;

    &.playing {
      z-index:2;
    }
  }

  .c-hero-overlay {
    position: absolute;
    z-index: 3;
    height: 50vh;
    width: 100%;
    bottom:0;
    background-color: rgba(19,19,19,0);
    background: linear-gradient(to bottom, rgba(19,19,19,0) 0%, rgba(1,1,1,0.48) 47%, rgba(0,0,0,0.52) 51%, rgba(0,0,0,1) 100%);
  }

  &.c-hero-single {
    @media (max-width: $mobile-bp) {
      height: 100vh;

      &-small {
        height: 72vh;
      }
    }

    @media (min-width: $mobile-bp) {
      height: calc(100vw / 1.6);
    }

    @media (min-width: $size-xl) {
      height: calc(100vw / 2);
    }

    @media (min-width: $size-2x) {
      height: calc(100vw / 2.8);
    }

    .c-hero-image,
    .c-hero-overlay {   
      @media (max-width: $mobile-bp) {
        height: 100vh;

        &-small {
          height: 72vh;
        }
      }

      @media (min-width: $mobile-bp) {
        height: calc(100vw / 1.6);
      }

      @media (min-width: $size-xl) {
        height: calc(100vw / 2);
      }

      @media (min-width: $size-2x) {
        height: calc(100vw / 2.8);
      }
    }

    .c-hero-overlay { 
      @media (min-width: $mobile-bp) {
        height: calc(50vw / 1.6);
      }
      @media (min-width: $size-xl) {
        height: calc(50vw / 2);
      }

      @media (min-width: $size-2x) {
        height: calc(50vw / 2.8);
      }
    }
  }
}

.c-hero-steps {
  position: absolute;
  z-index: 20;
  bottom: 100px;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: $unit-1 0;
  width: 100%;

  .step-item {
    flex: 1 1 0;
    margin-top: 0;
    min-height: 1rem;
    text-align: center;
    position: relative;

    &:not(:first-child)::before {
      background: $border-color-dark;
      content: "";
      height: 1px;
      left: -50%;
      position: absolute;
      top: 9px;
      width: 100%;
    }

    a {
      color: $gray-color;
      display: inline-block;
      padding: 40px 10px 0;
      text-decoration: none;
      font-size: 20px;
      line-height: 30px;
      font-family: 'Fjalla One';
      letter-spacing: 0.1em;
      text-transform: uppercase;
      transition: $global-transition;

      &::before {
        background: $border-color-dark;
        border: $border-width-lg solid $border-color-dark;
        border-radius: 50%;
        content: "";
        display: block;
        height: $unit-3;
        left: 50%;
        position: absolute;
        top: $unit-1;
        transform: translateX(-50%);
        width: $unit-3;
        z-index: $zindex-0;
      }

      &:hover {
        color: $light-color;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &.active {
      a {
        color: $light-color;
        &::before {
          background: $light-color;
          border: $border-width-lg solid $primary-color;
        }
      }

      & ~ .step-item {
        &::before {
          //background: $border-color;
        }

        a {
          &::before {
            //background: $gray-color-light;
          }
        }
      }
    }
  }
}

.c-hero-scrolldown {
  position: absolute;
  z-index: 20;
  bottom:20px;
  width:44px;
  height:44px;
  background: none;
  border:none;
  opacity: 0.3;
  left:50%;
  margin-left: -22px;
  background-image: url(../assets/images/ico-scrolldown.svg);
  background-repeat: no-repeat;
  background-size: 44px 44px;
  transition: $global-transition;
  animation: pop 2s infinite;
}

.c-header-hero-single-small .c-hero-scrolldown {
  display: none !important;
}

.c-hero-dotpagination {
  text-align: center;
  margin-top: 10px;

  .swiper-pagination-bullet {
    width:11px;
    height:11px;
    background: $light-color;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-size: 30px;

  &:focus {
    outline: none;
  }
}
.swiper-button-next {
  background-image: url(../assets/images/ico-weiter-light.svg);
  right:40px;
}
.swiper-button-prev {
  background-image: url(../assets/images/ico-zurueck-light.svg);
  left:40px;
}

.c-hero-dotpagination-gallery {
  text-align: center;
  margin-top: 10px;
  position: absolute;
  bottom: 60px;
  z-index: 10;

  .swiper-pagination-bullet {
    width:11px;
    height:11px;
    background: $light-color;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
