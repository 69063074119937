.c-teaser-rubrics {
  text-align: center;

  &-teaser {
    text-decoration: none;
    display: block;
    position: relative;
    height: calc(648px / 1.9);
    transition: $global-transition;

    @media (max-width: $mobile-bp) {
      height: calc(100vw / 1.9);
    }

    &--content {
      position: absolute;
      z-index: 3;
      width: 70%;
      left: 15%;
      top: 35%;
      //top: calc(50vw / 5);

      @media (max-width: $mobile-bp) {
        //top: calc(100vw / 5);
        top: 35%;
      }
    }

    &--title {
      margin-bottom: $unit-2;
      font-size: 40px;    
      line-height: 60px;
      //font-size: calc(50vw / 24);
      //line-height: calc(50vw / 16);
      color: $primary-color;

      @media (max-width: $mobile-bp) {
        font-size: 26px;
        line-height: 32px;
        //font-size: calc(100vw / 14.4);
        //line-height: calc(100vw / 12.5);
      }
    }
    &--subtitle {
      //top: 55%;
      font-size: 22px;
      line-height: 31px;
      //font-size: calc(50vw / 43.6);
      //line-height: calc(50vw / 31);
      font-weight: 300;
      color: $primary-color;
      opacity: 0.8;

      @media (max-width: $mobile-bp) {
        font-size: 16px;
        line-height: 24px;
        //font-size: calc(100vw / 23.4);
        //line-height: calc(100vw / 15.6);
      }
    }
    &--overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      opacity: 0.8;
      height: calc(648px / 1.9);

      @media (max-width: $mobile-bp) {
        height: calc(100vw / 1.9);
      }
    }
    &--background {
      position: absolute;
      z-index: 1;
      width: 100%;
      background-position: center center;
      background-size: cover;
      height: calc(648px / 1.9);

      @media (max-width: $mobile-bp) {
        height: calc(100vw / 1.9);
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}