.c-contact {
  .acceptance,
  .small {
    font-size: 14px;
    color:#868686;
  }
  .small {
    line-height: 50px;
  }
}

.c-contact-map {
  margin-top: 50px;
  margin-bottom: -160px;
  iframe {
    //height: calc(100vw / 4);
    //height: 700px; 
  }
}