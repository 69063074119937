.c-teaser-projects {

  &--item {
    position: relative;
    height: calc(100vw / 0.7);
    overflow: hidden;

    @media (min-width: $mobile-bp) {
      height: calc(50vw / 1.92);

      &:hover &--content {
        transform: translateY(-20px);
      }

      &:hover &--overlay {
        opacity:0;
      }
    }

    &:focus {
      box-shadow: none;
      text-decoration: none;
    }
    
    &--content {
      text-align: center;
      position: absolute;
      z-index: 3;
      width: 80%;
      left: 10%;
      top: 30%;
      transition: $global-transition;

      @media (min-width: $mobile-bp) {
        top: auto;
        text-align: left;
        bottom: 0;
        width: 70%;
        transform: translateY(50px);
      }
  
      @media (min-width: $size-xl) {
        width: 80%;
      }
  
      @media (min-width: $size-2x) {

      }

      &--location {
        font-size: 17px;
        color: $primary-color;
        opacity: 0.5;
        text-align: center;

        @media (min-width: $mobile-bp) {
          text-align: left;
        }
  
        span {
          background-image: url(../assets/images/ico-location.svg);
          background-repeat: no-repeat;
          padding-left: 30px;
        }
      }

      h3 {
        font-size: 32px;
        line-height: 40px;
        font-family: 'Fjalla One';
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-top: 15px;
        text-align: center;

        @media (min-width: $mobile-bp) {
          font-size: 36px;
          line-height: 48px;
          text-align: left;
        }
    
        @media (min-width: $size-xl) {
          font-size: 40px;
          line-height: 56px;
        }
      }
    }

    &--overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      transition: $global-transition;
      height: calc(100vw / 0.7);
      background: linear-gradient(45deg, rgba(19,19,19,0.9) 0%, rgba(23,23,23,0.8) 45%, rgba(28,28,28,0) 100%);

      @media (min-width: $mobile-bp) {
        height: calc(50vw / 1.92);
      }
    }

    &--background {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: calc(100vw / 0.7);
      background-size: cover;
      background-position: center center;

      @media (min-width: $mobile-bp) {
        height: calc(50vw / 1.92);
      }
    }
  }
}

body.single-referenz {
  a.menu-item-3098 {
    color: $primary-color;
    
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}