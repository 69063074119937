.u-hide {
  opacity:0;
  visibility: hidden;
}

.u-padless {
  padding: 0;
}

.u-text-highlight {
  color: $light-color;
}