.c-addressblock {
  border-top: 1px solid #111111;
  margin-top:50px;
  width: 100%;

  td {
    padding-top: 30px;
    width: 33%;
    color: #868686;
    font-size: 17px;
    line-height: 27px;
    font-weight: 300;
    vertical-align: top;
  }
}