@keyframes pulsate {
  0% {
    opacity: 1;
  } 
  50% {
    opacity: 0.7;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes pop {
  0% {
    transform: translateY(0);
  } 
  50% {
    transform: translateY(-5px);
  }
}

.animated {
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;

  &.infinite {
    animation-iteration-count: infinite;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}
