.c-blocks-services {
  @media (max-width: $mobile-bp) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: $mobile-bp) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .c-blocks-services--content {
    color: #868686;
    font-size: 17px;
    line-height: 27px;

    @media (max-width: $mobile-bp) {
      padding: 40px 20px 100px 20px;
    }

    @media (min-width: $mobile-bp) {
      padding: 40px 50px 100px 0;
    }

    h2 {
      color: $primary-color;
      position: relative;
      margin-bottom: 60px;

      @media (max-width: $mobile-bp) {
        font-size: 32px;
        line-height: 60px;
      }
    
      @media (min-width: $mobile-bp) {
        font-size: 40px;
        line-height: 60px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        bottom: -30px;
        background-color: #fff;
        transform: scaleX(1);
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }

  .c-blocks-services--image {
    @media (max-width: $mobile-bp) {
      padding: 0;
    }
  
    @media (min-width: $mobile-bp) {
      padding: 0 0 0 50px;
    }
  }

  .columns {
    @media (max-width: $mobile-bp) {
      flex-direction: column-reverse;
    }
  }

  .reverse {
    flex-direction: row-reverse;

    @media (max-width: $mobile-bp) {
      flex-direction: column-reverse;
    }

    .c-blocks-services--content {
      @media (max-width: $mobile-bp) {
        padding: 40px 20px 100px 20px;
      }
    
      @media (min-width: $mobile-bp) {
        padding: 40px 0 100px 50px;
      }
    }

    .c-blocks-services--image {
      @media (max-width: $mobile-bp) {
        padding: 0;
      }
    
      @media (min-width: $mobile-bp) {
        padding: 0 50px 0 0;
      }
    }
  }
}