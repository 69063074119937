.c-btn {
  display: inline-block;
  appearance: none;
  border-radius: 22px;
  border-width: 2px;
  border-color: $light-color;
  border-style: solid;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: none;
  color: $light-color;
  //padding: $unit-4 $unit-5 $unit-4;
  line-height: 44px;
  padding: 0 $unit-7 0 $unit-7;
  transition: $global-transition;

  @media (min-width: $mobile-bp) {
    &:hover {
      text-decoration: none;
      animation: pulsate 1s infinite;
      cursor: pointer;
    }
  }

  &:focus {
    text-decoration: none;
    box-shadow: none;
  }

  &--inverted {
    background: $light-color;
    color: $dark-color;

    @media (min-width: $mobile-bp) {
      &:hover {
        color: $dark-color;
      }
    }
  }
}

button.elm-button {
  display: inline-block;
  appearance: none;
  border-radius: 22px;
  border-width: 2px;
  border-color: $light-color;
  border-style: solid;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: $light-color;
  color: $dark-color;
  //padding: $unit-4 $unit-5 $unit-4;
  line-height: 44px;
  width: 200px;
  height: 48px;
  text-align: center;
  transition: $global-transition;

  @media (min-width: $mobile-bp) {
    &:hover {
      text-decoration: none;
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &:focus {
    outline: none;
  }


  .elm-loading-anim {
    margin-top: 4px;
  }
}